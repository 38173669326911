export default function CourseTable({name, description, courses}) {
  return (
    <div className="px-4 sm:px-6 lg:p-8 bg-white rounded-md">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">{name}</h1>
          <p className="mt-2 text-sm text-gray-700">
            {description} <span className="text-lg">&#128522;</span>
          </p>
        </div>
      </div>
      <div className="-mx-4 mt-8 sm:-mx-0">
            <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                # of Levels
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Languages
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Kind
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Start</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {courses.map((course) => (
              <tr key={course.name}>
                <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                  {course.name}
                  <dl className="font-normal lg:hidden">
                    <dt className="sr-only"># of Levels</dt>
                    <dd className="mt-1 truncate text-gray-700">{course.levels}</dd>
                  </dl>
                </td>
                <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{course.levels}</td>
                <td className="px-3 py-4 text-sm text-gray-500">{course.language}</td>
                <td className="px-3 py-4 text-sm text-gray-500">{course.kind}</td>
                <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                  <a href={course.url} target="_blank" className="text-indigo-600 hover:text-indigo-900">
                    {course.urlText}<span className="sr-only">, {course.name}</span>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
